<mat-form-field
  class="cp-warehouse app-wh-form-field-density cp-mat-options-fit-content no-top-margin no-user-select"
  appearance="outline"
  subscriptSizing="dynamic"
>
  <mat-select
    panelClass="drop-down"
    multiple
    (selectionChange)="filterByWareHouse()"
    required
    [formControl]="wareHouses"
    matTooltip="Select Warehouse(s)"
  >
    <mat-select-trigger *ngIf="(wareHouses.value?.length || 0) > 1">
      <span
        *ngIf="(wareHouses.value?.length || 0) > 1"
        class="example-additional-selection"
      >
        WAREHOUSES
      </span>
    </mat-select-trigger>
    @for (wareHouse of listOfWareHouses;track wareHouse) {
    <mat-option
      [value]="wareHouse?.facility_id"
      [disabled]="listOfWareHouses?.length === 1"
    >
      {{ wareHouse?.warehouse_name | uppercase }}
    </mat-option>
    }
  </mat-select>
  <mat-spinner
    *ngIf="showWareHouseSpinner"
    matSuffix
    [diameter]="20"
    strokeWidth="5"
    style="position: relative; right: 20px"
  >
  </mat-spinner>
</mat-form-field>
